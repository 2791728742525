import * as React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../../../components/Layout";
import { IMarkdownRemarkProps } from "../../../IMarkdownRemarkProps";

export default (props: IMarkdownRemarkProps) => {
  const { data } = props;
  const { edges: posts } = data.allMarkdownRemark;

  return (
    <Layout>
      <section>
        <div>
          <div>
            <Link to="/tags">Tags</Link>
            <h1>Latest Stories</h1>
          </div>
          {posts.map(({ node: post }) => (
            <div key={post.id}>
              <p>
                <Link to={post.fields.slug}>{post.frontmatter.title}</Link>
                <span> &bull; </span>
                <small>{post.frontmatter.date}</small>
              </p>
              <p>
                {post.excerpt}
                <br />
                <br />
                <Link to={post.fields.slug}>Keep Reading →</Link>
              </p>
            </div>
          ))}
        </div>
      </section>
    </Layout>
  );
};

export const pageQuery = graphql`
  query IndexQueryEN {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;
